import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Modal, Row, Col } from 'antd';
import './modal.scss';
import gstyles from '@vl/gstyles';

const ModalConfirm = ({ title, description, confirm, ignore, icon }) => {
  return (
    <DIV className="component">
      <Modal
        title={<div className="py-3 font-semibold text-base md:text-xl text-main">{title && title}</div>}
        bodyStyle={{ padding: 0 }}
        width={552}
        footer={null}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        visible={ctx.get('form.isModalVisible')}
        centered
        closeIcon={<div className="mt-8">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        wrapClassName={'my-modal'}
        onCancel={() => {
          ctx.apply('modalModel.hide');
        }}
      >
        <div className="p-4">
          <div className="px-2 py-4 flex flex-row items-center space-x-4">
            <div>{icon && icon}</div>
            {description && description}
          </div>
          <Row className="px-4 pt-6" justify="end">
            <Col xs={24} md={12}>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  {confirm()}
                </Col>
                <Col xs={24} md={12}>
                  {ignore()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </DIV>
  );
};

export default ModalConfirm;
