import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook(() => {
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              return {
                isModalVisible,
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              };
            }),

            templateItemInfo: hook((ctx) => {
              const template_id = ctx.get('@item.id');

              return {
                ...ctx.get('@item'),
                deleteTemplate: async () => {
                  try {
                    const payload = {
                      id: template_id,
                      set_program_template: {
                        deleted: 1,
                      },
                    };
                    const client = await getClient();
                    const res = await client.request(
                      gql`
                        mutation deleteProgramTemplate(
                          $id: uuid!
                          $set_program_template: b2b_program_template_set_input = {}
                        ) {
                          program_template: update_b2b_program_template_by_pk(
                            pk_columns: { id: $id }
                            _set: $set_program_template
                          ) {
                            id
                          }
                        }
                      `,
                      { ...payload }
                    );

                    if (!!res) ctx.apply('paginationModel.onRefresh');
                  } catch (err) {
                    console.log(err);
                  }
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
