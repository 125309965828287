import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ModalConfirm from '@uz/unitz-components-web/ModalConfirm';

const View8 = () => {
  return (
    <DIV className="component">
      <div className="p-8 w-full bg-white500 rounded-lg">
        <Row align="middle" justify="space-between">
          <Col>
            <Link to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplateDetail', ctx.get('templateItemInfo'))}>
              <div className="underline uppercase text-base font-semibold">
                {ctx.get('templateItemInfo.name')}
              </div>
            </Link>
          </Col>
          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Link>
                  <Button size="medium" type="primary" disabled>
                    {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Action.start')}
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link
                  to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplateDetail', ctx.get('templateItemInfo'))}
                >
                  <Button style={{ border: `1px solid ${gstyles.colors.placeHolder}` }} size="medium" type="outline">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.view')}
                  </Button>
                </Link>
              </Col>
              <Col>
                <ModalProvider>
                  <Button
                    size="medium"
                    name="red"
                    type="primary"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return (
                          <ModalConfirm
                            icon={gstyles.icons({ name: 'warning', size: 52 })}
                            title={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_delete_title')}
                            description={
                              <div className="px-4 text-xl font-semibold text-main">
                                {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_delete_description')}
                              </div>
                            }
                            confirm={() => {
                              return (
                                <Button
                                  type="primary"
                                  className="w-full"
                                  onClick={() => {
                                    ctx.apply('modalModel.hide');
                                  }}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.cancel')}
                                </Button>
                              );
                            }}
                            ignore={() => {
                              return (
                                <Button
                                  name="red"
                                  type="primary"
                                  className="w-full"
                                  onClick={ctx.get('templateItemInfo.deleteTemplate')}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.delete')}
                                </Button>
                              );
                            }}
                          />
                        );
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.delete')}
                  </Button>
                </ModalProvider>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(View8);
